/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Product,
  ProductDescription,
  ProductFeaturedText,
  TechnicalData
} from '@components/ecommerce';
import { Layout } from '@components/layout';
import React from 'react';

const ProductPage: React.FC = ({ pageContext }): JSX.Element => {
  const { page, product } = pageContext;

  console.log(product);

  return (
    <Layout {...page}>
      <Product product={product} />
      <ProductDescription product={product} />
      <ProductFeaturedText />
      <TechnicalData />
    </Layout>
  );
};

export default ProductPage;
